import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import EducationCurriculumDetail from './EducationCurriculumDetail';
import EducationCurriculumResult from './EducationCurriculumResult';

const EducationCurriculumBoard = (): JSX.Element => {
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();

    // URLを取得
    const params = useParams();
    const userId = params.userId;
    const educationCurriculumId = params.educationCurriculumId;

    // URLを参照してURL
    useEffect(() => {
        switch (location.pathname) {
            case `/hc-user/education/curriculum/result/${userId}/${educationCurriculumId}`:
                setActiveTab(1);
                break;
            case `/hc-user/education/curriculum/detail/${userId}/${educationCurriculumId}`:
                setActiveTab(2);
                break;
            default:
                break;
        }
    }, [location.pathname, userId, educationCurriculumId]);

    // タブ機能
    const handleTabClick = (tabNum) => {
        setActiveTab(tabNum);
        switch (tabNum) {
            case 1:
                navigate(`/hc-user/education/curriculum/result/${userId}/${educationCurriculumId}`);
                break;
            case 2:
                navigate(`/hc-user/education/curriculum/detail/${userId}/${educationCurriculumId}`);
                break;
            default:
                break;
        }
    }


    return (
        <div className="l-board">
            <div className="l-board__inner">
                <div className="p-board__tab">
                    <li
                        id="tab1"
                        className={`p-tab__btn ${activeTab === 1 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(1)}
                    >達成状況</li>
                    <li
                        id="tab2"
                        className={`p-tab__btn ${activeTab === 2 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(2)}
                    >カリキュラム</li>
                </div>
                <div
                    id="panel1"
                    className={`p-tab__panel ${activeTab === 1 ? 'is_active' : ''}`}
                >
                    <EducationCurriculumResult />
                </div>
                <div
                    id="panel2"
                    className={`p-tab__panel ${activeTab === 2 ? 'is_active' : ''}`}
                >
                    <EducationCurriculumDetail />
                </div>
            </div>
        </div>
    );
};
export default EducationCurriculumBoard;
