import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import InputTextField from '../Form/InputTextField';
import TextareaField from '../Form/TextareaField';
import SelectField from '../Form/SelectField';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';

const EducationCurriculumFormModal = ({
    isOpen,
    handleClose,
    mutationFunction,
    editData,
    educationCategories
}) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        mode: "onBlur",
        defaultValues: editData || {
            educationCategoryId: null,
            name: '',
            detail: '',
            period: '',
            mentor: '',
            educationPassCriterion: ''
        }
    });

    // 監視するフィールドの値を取得
    const watchedFields = watch();

    useEffect(() => {
        if (educationCategories && educationCategories.length > 0) {
            setValue('educationCategoryId', educationCategories[0].id);
        }
    }, [educationCategories, setValue]);

    const onSubmit = (data) => {
        if (editData && !isEqual(editData, watchedFields)) {
            mutationFunction.mutate({ ...data, id: editData.id });
        } else if (editData && isEqual(editData, watchedFields)) {
            toast.error('変更データがありません。');
        } else {
            mutationFunction.mutate(data);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="p-modal">
            <div className="p-modal__content">
                <button className="p-modal__delete" onClick={handleClose}>×</button>
                <h3 className="p-modal__headline">
                    {editData ? 'カリキュラム項目を編集' : 'カリキュラム項目を追加'}
                </h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* 対象のカテゴリー */}
                    <SelectField
                        label="対象のカテゴリー"
                        options={educationCategories.map(category => ({
                            label: category.name,
                            value: category.id
                        }))}
                        register={register}
                        name="educationCategoryId"
                        error={errors.educationCategoryId?.message}
                    />
                    {/* カリキュラム名 */}
                    <InputTextField
                        label="カリキュラム名"
                        type="text"
                        {...register("name", { required: "カリキュラム名は必須です。" })}
                        error={errors.name?.message}
                    />
                    {/* 教育担当者 */}
                    <InputTextField
                        label="教育担当者"
                        type="text"
                        {...register("mentor")}
                        error={errors.mentor?.message}
                    />
                    {/* レッスン時間および時期 */}
                    <InputTextField
                        label="レッスン時間および時期"
                        type="text"
                        {...register("period")}
                    />
                    {/* 詳細 */}
                    <TextareaField
                        label="詳細"
                        type="textarea"
                        {...register("detail", { required: "詳細は必須です。", maxLength: 2000 })}
                        error={errors.detail?.message}
                    />
                    {/* 合格基準 */}
                    <TextareaField
                        label="合格基準"
                        {...register("educationPassCriterion", { maxLength: 2000 })}
                        error={errors.educationPassCriterion?.message}
                    />
                    {/* 送信ボタン */}
                    <div className="p-input__area">
                        <button
                            className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                            type="submit"
                        >
                            {editData ? '更新する' : '追加する'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EducationCurriculumFormModal;
