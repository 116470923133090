
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../../../Utils/formatUtils";
/* Query */
import { useYearMonthSinceCreation } from "../../../Queries/CompanyQuery";
import { useUsersForSelectQuery } from "../../../Queries/UserQuery";
import { useTrainingRecordsByUserAndDate } from "../../../Queries/TrainingRecordQuery";
/* component */
import LoaderComponent from "../../../Components/Layout/Loader";
// import TrainingAddModal from "../../../Components/Modal/TrainingAddModal"; // コメントアウトされたまま
import TrainingRecordModal from "../../../Components/Modal/TrainingRecordModal";
import { useAuthority } from "../../../Contexts/AuthorityContext";

interface TrainingRecord {
  id: number;
  trainee_id: number;
  instructor_name: string;
  instructor_id: number;
  training_item: string;
  training_name: string;
  training_detail: string;
  complete_on: string; // 'YYYY-MM-DD' 形式の日付
  created_at?: string; // 'YYYY-MM-DD HH:mm:ss' 形式のタイムスタンプ
  updated_at?: string; // 'YYYY-MM-DD HH:mm:ss' 形式のタイムスタンプ
  start_time: string; // 'HH:mm:ss' 形式の時間
  finish_time: string; // 'HH:mm:ss' 形式の時間
}

const EducationTraining = (): JSX.Element => {
  // AuthorityContextからauthority, userShopId, userId, isLoadingを取得
  const { authority, userShopId, userId, isLoading: isAuthorityLoading } = useAuthority();

  useEffect(() => {
    console.log("Authority Value:", authority);
    console.log("Authority Type:", typeof authority);
  }, [authority]);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  /* ページ内データ */
  const [selectedTraineeId, setSelectedTraineeId] = useState<number | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>("");

  /* APIからデータを受け取る */
  const { data: trainees, status: traineesStatus } = useUsersForSelectQuery();
  const { data: yearMonthList, status: yearMonthListStatus } = useYearMonthSinceCreation();

  /* 追加モーダル */
  // const [isAddModal, setIsAddModal] = useState(false); // コメントアウトされたまま

  /* レコードモーダルのハンドラ */
  const [isRecordModal, setIsRecordModal] = useState<boolean>(false);

  const handleRecordModalOpen = () => {
    setIsRecordModal(true);
  };
  const handleRecordModalClose = () => {
    setIsRecordModal(false);
  };

  /* 選択系 */
  // 年月変更ハンドラ
  const handleYearMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYearMonth(event.target.value);
  };

  // トレーニー（ユーザー）変更ハンドラ
  const handleTraineeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTraineeId(Number(event.target.value));
  };

  // 研修詳細ページへのナビゲート
  const navigateTrainingDetail = (trainingRecordId: number) => {
    navigate(`/hc-user/education/training/detail/${trainingRecordId}`);
  };

  /* 現在ログインしているユーザーのデータを使用 */
  useEffect(() => {
    if (authority === 2 && userId) { // 数値 2 と比較
      console.log("Authority is 2 and userId:", userId);
      setSelectedTraineeId(userId);
    }
  }, [authority, userId]);

  /* ページ内データの初期設定 */
  useEffect(() => {
    if (authority !== 2) { // 数値 2 と比較
      if (trainees && trainees.length > 0) {
        console.log("Setting selectedTraineeId to first trainee:", trainees[0].id);
        setSelectedTraineeId(trainees[0].id);
      }
    }

    if (yearMonthList && yearMonthList.length > 0) {
      console.log("Setting selectedYearMonth to:", yearMonthList[0]);
      setSelectedYearMonth(yearMonthList[0]);
    }
  }, [trainees, yearMonthList, authority]);

  /* APIからのデータ取得 */
  const { data: trainingRecords, status: trainingRecordsStatus } =
    useTrainingRecordsByUserAndDate(selectedTraineeId, selectedYearMonth);

  /* ローディング状態の管理 */
  useEffect(() => {
    setIsLoading(
      isAuthorityLoading ||
      traineesStatus === "loading" ||
      yearMonthListStatus === "loading" ||
      trainingRecordsStatus === "loading"
    );
  }, [isAuthorityLoading, traineesStatus, yearMonthListStatus, trainingRecordsStatus]);

  /* デバッグ用ログ */
  useEffect(() => {
    console.log("Selected Trainee ID:", selectedTraineeId);
    console.log("Selected Year-Month:", selectedYearMonth);
  }, [selectedTraineeId, selectedYearMonth]);

  console.log("Training Records:", trainingRecords);

  /* エラーハンドリング */
  if (traineesStatus === "error") {
    return <div>トレーニー情報の取得に失敗しました。</div>;
  }

  if (yearMonthListStatus === "error") {
    return <div>年月情報の取得に失敗しました。</div>;
  }

  if (trainingRecordsStatus === "error") {
    return <div>研修記録の取得に失敗しました。</div>;
  }

  if (isLoading) return <LoaderComponent />;

  return (
    <>
      {/* 研修追加モーダル（必要に応じてコメント解除） */}
      {/* <TrainingAddModal
        isOpen={isAddModal}
        handleClose={handleAddModalClose}
      /> */}

      {/* 研修記録モーダル */}
      <TrainingRecordModal
        isOpen={isRecordModal}
        handleClose={handleRecordModalClose}
      />

      {/* ボタンエリア */}
      <div className="l-board__wrap p-board__btn no-border">
        <div className="l-flex p-board__btn--area space-around">

          {/* 年月セレクト */}
          <div className="p-board__btn--input">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleYearMonthChange}
              value={selectedYearMonth}
            >
              {yearMonthList &&
                yearMonthList.map((yearMonth, index) => {
                  // "-" で分割して、年と月を取得
                  const [year, month] = yearMonth.split("-");
                  return (
                    <option
                      key={index}
                      value={yearMonth}
                    >{`${year}年${month}月`}</option>
                  );
                })}
            </select>
          </div>

          {/* トレーニーセレクト（権限が「2」以外の場合のみ表示） */}
          {authority !== 2 && ( // 数値 2 と比較
            <div className="p-board__btn--input">
              <select
                className="c-input c-input__select type-shadow"
                onChange={handleTraineeChange}
                value={selectedTraineeId ?? ""}
              >
                {trainees &&
                  trainees.map((trainee, index) => (
                    <option key={index} value={trainee.id}>
                      {trainee.last_name} {trainee.first_name}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {/* 研修記録追加ボタン（権限が「0」または「1」の場合のみ表示） */}
          {(authority === 0 || authority === 1) && ( // 数値 0 または 1 と比較
            <div className="p-board__btn--input">
              <Link
                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                onClick={handleRecordModalOpen}
              >
                研修記録を追加
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* メインコンテンツ */}
      <div className="l-board__wrap p-board__main">
        <div className="p-board__main--box">
          <table className="p-table">
            <thead>
              <tr>
                <th>日付</th>
                <th>時間</th>
                <th>教育担当者</th>
                <th>研修項目</th>
                <th>研修カリキュラム名</th>
              </tr>
            </thead>
            <tbody>
              {trainingRecords && trainingRecords.data.length > 0 ? (
                trainingRecords.data.map((record: TrainingRecord, index: number) => (
                  <tr
                    key={index}
                    onClick={() => navigateTrainingDetail(record.id)}
                  >
                    <td>{formatDate(record.complete_on)}</td>
                    <td>
                      {formatTime(record.start_time)}-
                      {formatTime(record.finish_time)}
                    </td>
                    <td>{record.instructor_name}</td>
                    <td>{record.training_item}</td>
                    <td>{record.training_name}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>該当する研修記録がありません。</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default EducationTraining;
