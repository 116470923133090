import React, { useState, useEffect } from 'react';
import { useUsersForSelectQuery } from '../../Queries/UserQuery';
import LoaderComponent from '../Layout/Loader';
import { useStoreTrainingRecord, useTrainingRecordDetail, useUpdateTrainingRecord } from '../../Queries/TrainingRecordQuery';
import DatePicker from 'react-datepicker';
import { ja } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import { utcToZonedTime, format } from 'date-fns-tz';

interface TrainingRecordModalProps {
    isOpen: boolean;
    handleClose: () => void;
    refetchData?: () => void;
    trainingRecordId?: number;
}

const defaultTrainingData = {
    traineeId: '',
    instructorName: '',
    instructorId: '',
    trainingItem: '',
    trainingName: '',
    trainingDetail: '',
    completeOn: '',
};

const TrainingRecordModal: React.VFC<TrainingRecordModalProps> = ({ isOpen, handleClose, refetchData, trainingRecordId }) => {
    const { data: trainingDetail } = isOpen && trainingRecordId && trainingRecordId > 0 ? useTrainingRecordDetail(trainingRecordId) : { data: null };
    const { data: users } = useUsersForSelectQuery();
    const [instructorType, setInstructorType] = useState("inner");
    const [trainingData, setTrainingData] = useState(defaultTrainingData);
    const { mutate: storeTrainingRecord } = useStoreTrainingRecord(handleClose);
    const { mutate: updateTrainingRecord } = useUpdateTrainingRecord();

    const [startHour, setStartHour] = useState('09');
    const [startMinute, setStartMinute] = useState('00');
    const [finishHour, setFinishHour] = useState('17');
    const [finishMinute, setFinishMinute] = useState('00');

    // 編集モードと追加モードの切り替え
    const isEdit = trainingRecordId !== undefined;

    useEffect(() => {
        if (isEdit && isOpen && trainingDetail) {
            const jstDate = utcToZonedTime(trainingDetail.complete_on, 'Asia/Tokyo');
            const formattedDate = format(jstDate, 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' });

            setTrainingData({
                traineeId: trainingDetail.trainee_id,
                instructorName: trainingDetail.instructor_name,
                instructorId: trainingDetail.instructor_id,
                trainingItem: trainingDetail.training_item,
                trainingName: trainingDetail.training_name,
                trainingDetail: trainingDetail.training_detail,
                completeOn: formattedDate,
            });

            const [startHr, startMin] = trainingDetail.start_time.split(':');
            const [finishHr, finishMin] = trainingDetail.finish_time.split(':');
            setStartHour(startHr);
            setStartMinute(startMin);
            setFinishHour(finishHr);
            setFinishMinute(finishMin);
        } else {
            // 追加モードの場合
            const currentDate = new Date();
            if (users && users.length > 0) {
                // users 配列が存在し、要素を含む場合
                const firstUser = users[0];
                setTrainingData({
                    ...defaultTrainingData,
                    traineeId: firstUser.id,  // 初期 traineeId を設定
                    instructorId: firstUser.id,  // 初期 instructorId を設定
                    instructorName: `${firstUser.last_name} ${firstUser.first_name}`,  // 初期 instructorName を設定
                    completeOn: format(currentDate, 'yyyy/MM/dd'),
                });
            } else {
                setTrainingData({
                    ...defaultTrainingData,
                    completeOn: format(currentDate, 'yyyy/MM/dd'),
                });
            }
        }
    }, [isEdit, isOpen, trainingDetail, users]);  // users を依存配列に追加

    const handleUpdate = (field: keyof typeof trainingData) => (value: string | Date) => {
        let formattedValue;
        if (value instanceof Date) {
            // 日付の場合、フォーマットを適用
            formattedValue = format(value, 'yyyy/MM/dd');
        } else {
            formattedValue = value;
        }
        setTrainingData(prev => ({ ...prev, [field]: formattedValue }));
    };

    const handleInstructorTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newInstructorType = e.target.value;
        setInstructorType(newInstructorType);

        if (newInstructorType === 'outer') {
            // 外部レッスン担当者の場合、instructorNameを空にする
            setTrainingData(prev => ({ ...prev, instructorName: '' }));
        } else if (newInstructorType === 'inner' && users && users.length > 0) {
            // 社内レッスン担当者の場合、現在選択中のinstructorIdに基づいてinstructorNameを設定する
            const selectedUser = users.find(user => user.id === trainingData.instructorId) || users[0];
            setTrainingData(prev => ({
                ...prev,
                instructorName: `${selectedUser.last_name} ${selectedUser.first_name}`
            }));
        }
    };


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // 2桁のゼロ埋め形式に変換
        const formattedStartHour = startHour.padStart(2, '0');
        const formattedFinishHour = finishHour.padStart(2, '0');

        const dataToSend = {
            ...trainingData,
            startTime: `${formattedStartHour}:${startMinute}`,
            finishTime: `${formattedFinishHour}:${finishMinute}`,
        };

        if (isEdit) {
            updateTrainingRecord({ ...dataToSend, id: trainingRecordId });
        } else {
            storeTrainingRecord(dataToSend);
        }
    };

    const handleCloseBtnClick = () => {
        setInstructorType('inner');
        setTrainingData(defaultTrainingData);
        handleClose();
    };

    const isLoading = !users || (isEdit && !trainingDetail);

    if (!isOpen) return null;

    return (
        <div className="p-modal" onClick={handleCloseBtnClick}>
            <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
                <button className="p-modal__delete" onClick={handleCloseBtnClick}>
                    <img src='/image/icon_btn_batsu_green.png' alt="Close" />
                </button>
                <h3 className="p-modal__headline">{isEdit ? '研修記録更新' : '研修記録追加'}</h3>
                {isLoading ? (
                    <LoaderComponent />
                ) : (
                    <form onSubmit={handleSubmit}>
                        {/* 研修受講者選択 */}
                        <div className="p-input__area">
                            <div className="area-label">
                                <label className="c-label">研修受講者</label>
                            </div>
                            <div className="area-input">
                                <select
                                    className="c-input c-input__text"
                                    value={trainingData.traineeId}
                                    onChange={(e) => handleUpdate('traineeId')(e.target.value)}
                                >
                                    {users.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.last_name} {user.first_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* 研修担当者の選択 */}
                        <div className="p-input__area">
                            <div className="area-label">
                                <label className="c-label">研修担当者</label>
                            </div>
                            <div className="c-input__checkbox">
                                <input
                                    id="inner"
                                    type="radio"
                                    name="educatorType"
                                    value="inner"
                                    checked={instructorType === 'inner'}
                                    onChange={handleInstructorTypeChange}
                                />
                                <label htmlFor="inner">社内レッスン担当者</label>
                            </div>
                            <div className="c-input__checkbox">
                                <input
                                    id="outer"
                                    type="radio"
                                    name="educatorType"
                                    value="outer"
                                    checked={instructorType === 'outer'}
                                    onChange={handleInstructorTypeChange}
                                />
                                <label htmlFor="outer">外部レッスン担当者</label>
                            </div>
                            {instructorType === 'inner' && (
                                <div className="area-input">
                                    <select
                                        className="c-input c-input__text"
                                        value={trainingData.instructorId}
                                        onChange={(e) => handleUpdate('instructorId')(e.target.value)}
                                    >
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>
                                                {user.last_name} {user.first_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {instructorType === 'outer' && (
                                <div className="area-input">
                                    <input
                                        className="c-input c-input__text"
                                        type="text"
                                        value={trainingData.instructorName}
                                        onChange={(e) => handleUpdate('instructorName')(e.target.value)}
                                    />
                                </div>
                            )}
                        </div>

                        {/* 研修項目、研修名、研修記録 */}
                        <div className="p-input__area">
                            <label className="c-label">研修項目</label>
                            <input
                                className="c-input u-mt4"
                                type="text"
                                value={trainingData.trainingItem}
                                onChange={(e) => handleUpdate('trainingItem')(e.target.value)}
                            />
                        </div>
                        <div className="p-input__area">
                            <label className="c-label">研修名</label>
                            <input
                                className="c-input u-mt4"
                                type="text"
                                value={trainingData.trainingName}
                                onChange={(e) => handleUpdate('trainingName')(e.target.value)}
                            />
                        </div>
                        <div className="p-input__area">
                            <div className="area-label">
                                <label className="c-label">研修実施日</label>
                            </div>
                            <div className="area-input">
                                <DatePicker
                                    className="c-input c-input__text"
                                    selected={trainingData.completeOn ? new Date(trainingData.completeOn) : null}
                                    onChange={(date) => handleUpdate('completeOn')(date)}
                                    dateFormat="yyyy/MM/dd"
                                    locale={ja}
                                />
                            </div>
                        </div>
                        <div className="p-input__area">
                            <label className="c-label">開始・終了時間</label>
                            <div className="area-input l-flex">
                                <div className="c-box__two">
                                    <label className="c-label c-label__sub">開始時間</label>
                                    <div className="l-flex">
                                        <select
                                            className="c-input c-input__time"
                                            value={startHour}
                                            onChange={(e) => setStartHour(e.target.value)}
                                        >
                                            {[...Array(24)].map((_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                        <span>:</span>
                                        <select
                                            className="c-input c-input__time"
                                            value={startMinute}
                                            onChange={(e) => setStartMinute(e.target.value)}
                                        >
                                            {[...Array(60)].map((_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="c-box__two">
                                    <label className="c-label c-label__sub">終了時間</label>
                                    <div className="l-flex">
                                        <select
                                            className="c-input c-input__time"
                                            value={finishHour}
                                            onChange={(e) => setFinishHour(e.target.value)}
                                        >
                                            {[...Array(24)].map((_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                        <span>:</span>
                                        <select
                                            className="c-input c-input__time"
                                            value={finishMinute}
                                            onChange={(e) => setFinishMinute(e.target.value)}
                                        >
                                            {[...Array(60)].map((_, i) => (
                                                <option key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-input__area">
                            <label className="c-label">研修記録</label>
                            <textarea
                                className="c-input c-input__textarea u-mt4"
                                value={trainingData.trainingDetail}
                                onChange={(e) => handleUpdate('trainingDetail')(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="p-input__area">
                            <button
                                className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                                type="submit"
                            >
                                {isEdit ? '更新' : '追加'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};
export default TrainingRecordModal;
