import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RadarChart from "./components/RadarChart";
import DonutChart from "./components/DonutChart";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useUsersForSelectQuery } from "../../../Queries/UserQuery";
import { useEvaluationPeriods } from "../../../Queries/EvaluationRuleQuery"; // 評価期間
import { getResultsForUser } from "../../../Queries/EvaluationResultQuery"; // ユーザーの評価
import { useExportPerformanceReviewMutate } from "../../../Queries/ExcelExportQuery";
// import { useOverallReview } from '../../../Queries/OverallReviewQuery';
import { useAuthority } from "../../../Contexts/AuthorityContext";

const HrEvaluation = (): JSX.Element => {
  const { authority } = useAuthority();
  /* ページ内データ */
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedPeriodStart, setSelectedPeriodStart] = useState<string | null>(
    null
  );
  const [selectedPeriodEnd, setSelectedPeriodEnd] = useState<string | null>(
    null
  );
  const [selectedUserName, setSelectedUserName] = useState("");

  /* 取得API */
  const { data: users, status: usersStatus } = useUsersForSelectQuery();
  const { data: periods, status: periodsStatus } = useEvaluationPeriods();

  const exportPerformanceReview = useExportPerformanceReviewMutate();

  const [overallReview, setOverallReview] = useState(null);
  const [evaluationResults, setEvaluationResults] = useState(null);

  const formatPeriod = (period) => {
    let startYear = period.start.slice(0, 4).slice(-2);
    let startMonth = parseInt(period.start.slice(4, 6), 10);
    let endYear = period.end.slice(0, 4).slice(-2);
    let endMonth = parseInt(period.end.slice(4, 6), 10);
    return `${startYear}年${startMonth}月-${endYear}年${endMonth}月`;
  };

  /* ローディング */
  const [isLoading, setIsLoading] = useState(true);

  // 月日を各APIで仕様する引数に設定
  useEffect(() => {
    if (users && users.length > 0) {
      setSelectedUserId(users[0].id);
    }
    if (periods && periods.length > 0) {
      setSelectedPeriodStart(periods[0].start);
      setSelectedPeriodEnd(periods[0].end);
    }
  }, [users, periods]);

  // 評価実績を取得
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedUserId || !selectedPeriodStart) return;

      setIsLoading(true);
      try {
        const data = await getResultsForUser(
          selectedUserId,
          selectedPeriodStart
        );
        if (
          data.role_evaluations &&
          data.job_evaluations &&
          data.performance_evaluations &&
          data.work_evaluations
        ) {
          setEvaluationResults(data);
        } else {
          // データが期待する形式ではない場合の処理をここに書く
        }
        setOverallReview(data.review);
      } catch (error) {
        console.error("Error fetching evaluation results:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [selectedUserId, selectedPeriodStart]);

  // ローディング初期時
  useEffect(() => {
    setIsLoading(usersStatus === "loading" || periodsStatus === "loading");
  }, [usersStatus, periodsStatus]);
  // スタッフ名を取得
  useEffect(() => {
    if (selectedUserId && users) {
      const user = users.find((u) => u.id === selectedUserId);
      if (user) {
        setSelectedUserName(`${user.last_name} ${user.first_name}`);
      }
    }
  }, [selectedUserId, users]);

  // 人事考課表ダウンロードボタンのクリックイベントハンドラ
  const handleDownload = async () => {
    exportPerformanceReview(
      selectedUserId,
      selectedPeriodStart,
      selectedUserName
    );
  };

  /* 選択系 */
  // ユーザー
  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUserId(userId);
    const user = users.find((u) => u.id === parseInt(userId, 10));
    if (user) {
      setSelectedUserName(`${user.last_name} ${user.first_name}`);
    }
  };

  // 期間
  const handlePeriodChange = (event) => {
    const [start, end] = event.target.value.split("-"); // 1. ハイフンで開始と終了に分割
    setSelectedPeriodStart(start); // 2. 開始日付を設定
    setSelectedPeriodEnd(end); // 3. 終了日付を設定
  };

  /* 総合評価 */
  const hasEvaluationResults = (results) => {
    return (
      results &&
      results.role_evaluations &&
      results.job_evaluations &&
      results.performance_evaluations
    );
  };
  // 各評価の合計を計算
  const calculateTotalEvaluations = (evaluations) => {
    return evaluations
      ? evaluations.reduce((acc, evaluation) => acc + evaluation.result, 0)
      : 0;
  };

  const totalRoleEvaluations = calculateTotalEvaluations(
    evaluationResults?.role_evaluations
  );
  const totalJobEvaluations = calculateTotalEvaluations(
    evaluationResults?.job_evaluations
  );
  const totalPerformanceEvaluations = calculateTotalEvaluations(
    evaluationResults?.performance_evaluations
  );
  const totalWorkEvaluations = calculateTotalEvaluations(
    evaluationResults?.work_evaluations
  );

  // baseNumerator（加点なし分子）の計算
  const baseNumerator =
    totalRoleEvaluations + totalJobEvaluations + totalPerformanceEvaluations;

  // totalNumerator（加点込み分子）の計算
  const totalNumerator = baseNumerator + totalWorkEvaluations;

  // 分母の計算
  // const denominator = hasEvaluationResults(evaluationResults)
  //     ? (evaluationResults.role_evaluations.length +
  //         evaluationResults.job_evaluations.length +
  //         evaluationResults.performance_evaluations.length) * 7
  //     : 0;

  // 加点抜きの分母
  const baseDenominator = hasEvaluationResults(evaluationResults)
    ? (evaluationResults.role_evaluations.length +
        evaluationResults.job_evaluations.length +
        evaluationResults.performance_evaluations.length) *
      7
    : 0;

  // 加点込みの分母
  const totalDenominator = hasEvaluationResults(evaluationResults)
    ? (evaluationResults.role_evaluations.length +
        evaluationResults.job_evaluations.length +
        evaluationResults.performance_evaluations.length +
        (evaluationResults.work_evaluations
          ? evaluationResults.work_evaluations.length
          : 0)) *
      7
    : 0;

  const roleAndJobEvaluations =
    evaluationResults &&
    evaluationResults.role_evaluations &&
    evaluationResults.job_evaluations
      ? [
          ...evaluationResults.role_evaluations,
          ...evaluationResults.job_evaluations,
        ].map((evaluation) => ({
          subject: evaluation.name,
          A: evaluation.result,
        }))
      : null;

  const performanceEvaluations =
    evaluationResults && evaluationResults.performance_evaluations
      ? [...evaluationResults.performance_evaluations].map((evaluation) => ({
          subject: evaluation.name,
          A: evaluation.result,
        }))
      : null;

  if (isLoading) return <LoaderComponent />;

  return (
    <>
      <div className="l-board__wrap p-board__btn">
        <div className="l-flex p-board__btn--area">
          <div className="p-board__btn--input">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handlePeriodChange}
              value={`${selectedPeriodStart}-${selectedPeriodEnd}`}
            >
              {periods &&
                periods.map(
                  (
                    period,
                    index // 3. Map through the periods
                  ) => (
                    <option key={index} value={`${period.start}-${period.end}`}>
                      {formatPeriod(period)}
                    </option>
                  )
                )}
            </select>
          </div>
          <div className="p-board__btn--input">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleUserChange}
              value={selectedUserId}
            >
              {users &&
                users.map((user, index) => (
                  <option key={index} value={user.id}>
                    {user.last_name} {user.first_name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="l-flex p-board__btn--area space-around">
          {authority == "0" && (
            <Link
              to={`/hc-user/hr/evaluation/edit/${selectedUserId}/${selectedPeriodStart}`}
              className="p-board__btn--item c-btn c-btn__blue"
            >
              考課する
            </Link>
          )}
          <Link
            to={"/hc-user/hr/evaluation/compare"}
            className="p-board__btn--item c-btn c-btn__blue"
          >
            比較する
          </Link>
        </div>
      </div>
      {authority == "0" && (
        <div className="l-board__wrap p-board__btn">
          <button
            className="p-board__btn--item c-btn c-btn__green icon icon-download"
            onClick={handleDownload}
          >
            人事考課表をダウンロード
          </button>
        </div>
      )}
      <div className="l-board__wrap p-board__main border-bottom">
        <h3 className="c-headline__main">総合評価</h3>
        <div className="p-eva__box">
          <div className="p-eva__total__wrap">
            <div className="p-eva__total p-eva__total--num">
              <h3 className="c-headline__sub">加点込み総合評価</h3>
              <div className="u-tAlign--center">
                <DonutChart
                  numerator={totalNumerator}
                  denominator={totalDenominator}
                />
              </div>
              <p className="p-eva__total__fraction">
                {totalDenominator > 0
                  ? `${totalNumerator}/${totalDenominator}`
                  : "データがありません"}
              </p>
            </div>
            <div className="p-eva__total p-eva__total--num">
              <h3 className="c-headline__sub">加点抜き総合評価</h3>
              {/* <div className="u-tAlign--center"><DonutChart /></div> */}
              <DonutChart
                numerator={baseNumerator}
                denominator={baseDenominator}
              />
              <p className="p-eva__total__fraction">
                {baseDenominator > 0
                  ? `${baseNumerator}/${baseDenominator}`
                  : "データがありません"}
              </p>
            </div>
          </div>
          <div className="p-eva__total p-eva__total--desc">
            <p className="area-content">
              {overallReview ? (
                overallReview
              ) : (
                <span className="u-tSub">総評は未設定です。</span>
              )}
            </p>
          </div>
        </div>
      </div>
      {/* 仕事・役割 */}
      <div className="l-board__wrap p-board__main border-bottom">
        <h3 className="c-headline__main">仕事評価・役割評価（7段階）</h3>
        <div className="p-eva__box">
          <div className="area-data">
            <RadarChart
              beforeEvaluationData={roleAndJobEvaluations}
              // afterEvaluationData={roleAndJobEvaluations}
            />
          </div>
          <div className="area-content">
            <ul className="p-eva__list">
              {evaluationResults &&
                evaluationResults.job_evaluations.map((evaluation, index) => (
                  <li key={index} className="p-eva__list--item">
                    <p className="ttl">{evaluation.name}</p>
                    <p className="data">
                      {evaluation.result === 0 ? (
                        <span className="u-tSub">-</span>
                      ) : (
                        evaluation.result
                      )}
                    </p>
                  </li>
                ))}
              {evaluationResults &&
                evaluationResults.role_evaluations &&
                evaluationResults.role_evaluations.map((evaluation, index) => (
                  <li key={index} className="p-eva__list--item">
                    <p className="ttl">{evaluation.name}</p>
                    <p className="data">
                      {evaluation.result === 0 ? (
                        <span className="u-tSub">-</span>
                      ) : (
                        evaluation.result
                      )}
                    </p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      {/* 業績 */}
      <div className="l-board__wrap p-board__main border-bottom">
        <h3 className="c-headline__main">業績評価（7段階）</h3>
        <div className="p-eva__box">
          <div className="area-data">
            <RadarChart beforeEvaluationData={performanceEvaluations} />
          </div>
          <div className="area-content">
            <ul className="p-eva__list">
              {evaluationResults &&
                evaluationResults.performance_evaluations.map(
                  (evaluation, index) => (
                    <li key={index} className="p-eva__list--item">
                      <p className="ttl">{evaluation.name}</p>
                      <p className="data">
                        {evaluation.result === 0 ? (
                          <span className="u-tSub">-</span>
                        ) : (
                          evaluation.result
                        )}
                      </p>
                    </li>
                  )
                )}
            </ul>
          </div>
        </div>
      </div>
      {/* 勤務 */}
      <div className="l-board__wrap p-board__main">
        <h3 className="c-headline__main">勤務評価（加点）</h3>
        <div className="p-eva__box">
          <p className="area-data">+{totalWorkEvaluations}</p>
          <div className="area-content">
            <ul className="p-eva__list">
              {evaluationResults &&
                evaluationResults.work_evaluations.map((evaluation, index) => (
                  <li key={index} className="p-eva__list--item">
                    <p className="ttl">{evaluation.name}</p>
                    <p className="data">
                      {evaluation.result === 0 ? (
                        <span className="u-tSub">-</span>
                      ) : (
                        `+${evaluation.result}`
                      )}
                    </p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrEvaluation;
