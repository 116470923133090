import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEducationCategories } from "../../../Queries/EducationCategoryQuery";
import { useGetEducationCurriculumDetail, useUpdateEducationCurriculum, useDeleteEducationCurriculum } from '../../../Queries/EducationCurriculumQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import ConfirmDeleteModal from '../../../Components/Modal/ConfirmDeleteModal';
import EducationCurriculumFormModal from '../../../Components/Modal/EducationCurriculumFormModal';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const EducationCurriculumDetail = (): JSX.Element => {
    const { authority } = useAuthority();
    const { educationCurriculumId } = useParams<{ educationCurriculumId: string }>();
    const { data: curriculumDetail, status: curriculumDetailStatus } = useGetEducationCurriculumDetail(Number(educationCurriculumId));
    const { data: educationCategories, status: educationCategoriesStatus } = useEducationCategories();

    const handleCloseModal = () => {
        setIsEditModal(false);
    };
    const updateCurriculum = useUpdateEducationCurriculum(handleCloseModal);
    const deleteCurriculum = useDeleteEducationCurriculum();

    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const handleDeleteModalOpen = () => setIsDeleteModal(true);
    const handleDeleteModalClose = () => setIsDeleteModal(false);

    const [isEditModal, setIsEditModal] = useState(false);
    const handleEditModalOpen = () => setIsEditModal(true);
    const handleEditModalClose = () => setIsEditModal(false);

    const isLoading = curriculumDetailStatus === 'loading' || educationCategoriesStatus === 'loading';

    if(isLoading) return <LoaderComponent />

    return (
        <>
            <ConfirmDeleteModal
                title="カリキュラム削除"
                isOpen={isDeleteModal}
                handleClose={handleDeleteModalClose}
                selectedId={Number(educationCurriculumId)}
                deleteMutation={deleteCurriculum}
            />
            <EducationCurriculumFormModal
                isOpen={isEditModal}
                handleClose={handleEditModalClose}
                mutationFunction={updateCurriculum}
                editData={curriculumDetail}
                educationCategories={educationCategories}
            />
            { (authority === '0' || authority === '1') && (
                <div className="l-board__wrap p-board__btn no-border">
                    <div className="l-board__btn--box">
                        <button
                            className="c-btn c-btn__blue c-btn__table"
                            onClick={handleEditModalOpen}
                        >編集</button>
                    </div>
                    <div className="l-board__btn--box">
                        <button
                            className="c-btn c-btn__red c-btn__table"
                            onClick={handleDeleteModalOpen}>削除</button>
                    </div>
                </div>
            )}
            <div className="l-board__wrap p-board__main">
                <table className="p-table p-table__detail">
                    <tbody>
                        <tr>
                            <td className="headline">教育カテゴリー</td>
                            <td>{curriculumDetail.educationCategoryName}</td>
                        </tr>
                        <tr>
                            <td className="headline">教育担当者</td>
                            <td>{curriculumDetail.mentor}</td>
                        </tr>
                        <tr>
                            <td className="headline">名称</td>
                            <td>{curriculumDetail.name}</td>
                        </tr>
                        <tr>
                            <td className="headline">レッスン時間および時期</td>
                            <td>{curriculumDetail.period}</td>
                        </tr>
                        <tr>
                            <td className="headline">詳細</td>
                            <td>{curriculumDetail.detail}</td>
                        </tr>
                        <tr>
                            <td className="headline">合格基準</td>
                            <td>{curriculumDetail.educationPassCriterion}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default EducationCurriculumDetail;
