import React, { useState, useEffect } from "react";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useEvaluationPeriods } from "../../../Queries/EvaluationRuleQuery";
import { getComparisonResultsForUser } from "../../../Queries/EvaluationResultQuery";
import { useUsersForSelectQuery } from "../../../Queries/UserQuery";
import RadarChart from "./components/RadarChart";

const HrCompareEvaluation = (): JSX.Element => {
  // API
  const { data: users, status: usersStatus } = useUsersForSelectQuery();
  const { data: periods, status: periodsStatus } = useEvaluationPeriods();

  // 状態管理
  // const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | string>("");

  // const [beforePeriodStart, setBeforePeriodStart] = useState<string | null>(null);
  // const [beforePeriodEnd, setBeforePeriodEnd] = useState<string | null>(null);
  const [beforePeriodStart, setBeforePeriodStart] = useState<string>("");
  const [beforePeriodEnd, setBeforePeriodEnd] = useState<string>("");
  const [beforeTotalScore, setBeforeTotalScore] = useState("0/0");

  // const [afterPeriodStart, setAfterPeriodStart] = useState<string | null>(null);
  // const [afterPeriodEnd, setAfterPeriodEnd] = useState<string | null>(null);
  const [afterPeriodStart, setAfterPeriodStart] = useState<string>("");
  const [afterPeriodEnd, setAfterPeriodEnd] = useState<string>("");
  const [afterTotalScore, setAfterTotalScore] = useState("0/0");

  const [comparisonResults, setComparisonResults] = useState<any>(null);

  const formatPeriod = (period) => {
    let startYear = period.start.slice(0, 4).slice(-2);
    let startMonth = parseInt(period.start.slice(4, 6), 10);
    let endYear = period.end.slice(0, 4).slice(-2);
    let endMonth = parseInt(period.end.slice(4, 6), 10);
    return `${startYear}年${startMonth}月-${endYear}年${endMonth}月`;
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (users && users.length > 0) {
      setSelectedUserId(users[0].id);
    }
    if (periods && periods.length > 0) {
      setBeforePeriodStart(periods[0].start);
      setBeforePeriodEnd(periods[0].end);
      setAfterPeriodStart(periods[0].start);
      setAfterPeriodEnd(periods[0].end);
    }
  }, [users, periods]);

  // 評価実績を取得
  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (selectedUserId && beforePeriodStart && afterPeriodStart) {
        const beforeYM = `${beforePeriodStart}${beforePeriodEnd}`;
        const afterYM = `${afterPeriodStart}${afterPeriodEnd}`;
        const data = await getComparisonResultsForUser(
          selectedUserId.toString(),
          beforeYM,
          afterYM
        );
        setComparisonResults(data);
      }
    } catch (error) {
      console.error("Error fetching evaluation comparison results:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedUserId,
    beforePeriodStart,
    beforePeriodEnd,
    afterPeriodStart,
    afterPeriodEnd,
  ]);

  // トータル
  useEffect(() => {
    if (comparisonResults) {
      const newBeforeTotalScore = calculateTotalScore(
        comparisonResults,
        "before"
      );
      setBeforeTotalScore(newBeforeTotalScore); // beforeTotalScoreのステートを更新
      const newAfterTotalScore = calculateTotalScore(
        comparisonResults,
        "after"
      );
      setAfterTotalScore(newAfterTotalScore); // afterTotalScoreのステートを更新
    }
  }, [comparisonResults]);
  /* select */
  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
  };

  const handleBeforePeriodChange = (event) => {
    const [start, end] = event.target.value.split("-"); // 1. ハイフンで開始と終了に分割
    setBeforePeriodStart(start); // 2. 開始日付を設定
    setBeforePeriodEnd(end); // 3. 終了日付を設定
  };

  const handleAfterPeriodChange = (event) => {
    const [start, end] = event.target.value.split("-"); // 1. ハイフンで開始と終了に分割
    setAfterPeriodStart(start); // 2. 開始日付を設定
    setAfterPeriodEnd(end); // 3. 終了日付を設定
  };

  const calculateTotalScore = (comparisonResults, type = "before") => {
    if (!comparisonResults) return "0/0";

    // 分子の計算（各評価のtypeに応じたResultの合計）
    const numerator =
      comparisonResults.job_evaluations.reduce(
        (acc, evaluation) => acc + evaluation[type + "Result"],
        0
      ) +
      comparisonResults.performance_evaluations.reduce(
        (acc, evaluation) => acc + evaluation[type + "Result"],
        0
      ) +
      comparisonResults.role_evaluations.reduce(
        (acc, evaluation) => acc + evaluation[type + "Result"],
        0
      ) +
      comparisonResults.work_evaluations.reduce(
        (acc, evaluation) => acc + evaluation[type + "Result"],
        0
      );

    // 分母の計算（項目数×7、勤務評価を含む）
    const denominator =
      (comparisonResults.job_evaluations.length +
        comparisonResults.performance_evaluations.length +
        comparisonResults.role_evaluations.length +
        comparisonResults.work_evaluations.length) * // ここで勤務評価を含める
      7;

    // 分子と分母を文字列として結合して返す
    return `${numerator}/${denominator}`;
  };

  /* レーダーチャート */
  const formatEvaluationDataForRadarChart = (evaluations, type) => {
    return evaluations.map((evaluation) => ({
      subject: evaluation.name,
      A: evaluation[type + "Result"],
    }));
  };

  const getBeforeEvaluationData = () => {
    if (!comparisonResults) return [];
    return [
      ...formatEvaluationDataForRadarChart(
        comparisonResults.job_evaluations,
        "before"
      ),
      ...formatEvaluationDataForRadarChart(
        comparisonResults.role_evaluations,
        "before"
      ),
    ];
  };

  const getAfterEvaluationData = () => {
    if (!comparisonResults) return [];
    return [
      ...formatEvaluationDataForRadarChart(
        comparisonResults.job_evaluations,
        "after"
      ),
      ...formatEvaluationDataForRadarChart(
        comparisonResults.role_evaluations,
        "after"
      ),
    ];
  };

  const getBeforePerformanceData = () => {
    if (!comparisonResults) return [];
    return formatEvaluationDataForRadarChart(
      comparisonResults.performance_evaluations,
      "before"
    );
  };

  const getAfterPerformanceData = () => {
    if (!comparisonResults) return [];
    return formatEvaluationDataForRadarChart(
      comparisonResults.performance_evaluations,
      "after"
    );
  };

  // ローディング初期時
  useEffect(() => {
    setIsLoading(usersStatus === "loading" || periodsStatus === "loading");
  }, [usersStatus, periodsStatus]);

  if (isLoading) return <LoaderComponent />;

  return (
    <div className="l-board">
      <div className="l-board__inner">
        {/* 比較 */}
        <div className="l-board__wrap p-comp__headline p-comp__content">
          <div className="p-comp__headline-ttl"></div>
          <div className="p-comp__headline-select p-comp__headline-user">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleUserChange}
              // value={selectedUserId}
              value={selectedUserId || ""}
            >
              {users &&
                users.map((user, index) => (
                  <option key={index} value={user.id}>
                    {user.last_name} {user.first_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="p-comp__headline-select p-comp__headline-beforeDate">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleBeforePeriodChange}
              value={`${beforePeriodStart}-${beforePeriodEnd}`}
            >
              {periods &&
                periods.map(
                  (
                    period,
                    index // 3. Map through the periods
                  ) => (
                    <option key={index} value={`${period.start}-${period.end}`}>
                      {formatPeriod(period)}
                    </option>
                  )
                )}
            </select>
          </div>
          <div className="p-comp__headline-select p-comp__headline-afterDate">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleAfterPeriodChange}
              value={`${afterPeriodStart}-${afterPeriodEnd}`}
            >
              {periods &&
                periods.map(
                  (
                    period,
                    index // 3. Map through the periods
                  ) => (
                    <option key={index} value={`${period.start}-${period.end}`}>
                      {formatPeriod(period)}
                    </option>
                  )
                )}
            </select>
          </div>
        </div>

        {/* 総合評価 */}
        <div className="l-board__wrap p-comp__content">
          <div className="p-compare__box">
            <p className="p-compare__ttl">総合評価</p>
            <div className="p-compare__content">
              <p className="content-headline"></p>
              <div className="content-inner">
                <div className="content-item">
                  <span className="total">{beforeTotalScore}</span>
                </div>
                <div className="content-item">
                  <span className="total">{afterTotalScore}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 仕事・役割評価 */}
        <div className="l-board__wrap p-comp__content">
          <div className="p-compare__box">
            <p className="p-compare__ttl">
              仕事評価
              <br />
              役割評価
            </p>
            <div className="p-compare__content">
              <p className="content-headline"></p>
              <div className="content-inner content-chart">
                <RadarChart
                  beforeEvaluationData={getBeforeEvaluationData()}
                  afterEvaluationData={getAfterEvaluationData()}
                />
              </div>
            </div>
          </div>
          {comparisonResults &&
            comparisonResults.job_evaluations.map((jobEval, index) => (
              <div className="p-compare__box" key={index}>
                <p className="p-compare__ttl"></p>
                <div className="p-compare__content">
                  <p className="content-headline">{jobEval.name}</p>
                  <div className="content-inner">
                    <div className="content-item">
                      <span className="data color-orange">
                        {jobEval.beforeResult}
                      </span>
                    </div>
                    <div className="content-item">
                      <span className="data">{jobEval.afterResult}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {comparisonResults &&
            comparisonResults.role_evaluations.map((roleEval, index) => (
              <div className="p-compare__box" key={index}>
                <p className="p-compare__ttl"></p>
                <div className="p-compare__content">
                  <p className="content-headline">{roleEval.name}</p>
                  <div className="content-inner">
                    <div className="content-item">
                      <span className="data color-orange">
                        {roleEval.beforeResult}
                      </span>
                    </div>
                    <div className="content-item">
                      <span className="data">{roleEval.afterResult}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* 業績評価 */}
        <div className="l-board__wrap p-comp__content">
          <div className="p-compare__box">
            <p className="p-compare__ttl">業績評価</p>
            <div className="p-compare__content">
              <p className="content-headline"></p>
              <div className="content-inner content-chart">
                {/* <RadarChart /> */}
                <RadarChart
                  beforeEvaluationData={getBeforePerformanceData()}
                  afterEvaluationData={getAfterPerformanceData()}
                />
              </div>
            </div>
          </div>
          {comparisonResults &&
            comparisonResults.performance_evaluations.map((perEval, index) => (
              <div className="p-compare__box" key={index}>
                {/* Only display "仕事評価" for the first item */}
                {/* <p className="p-compare__ttl">{index === 0 ? '業績評価' : ''}</p> */}
                <p className="p-compare__ttl"></p>
                <div className="p-compare__content">
                  <p className="content-headline">{perEval.name}</p>
                  <div className="content-inner">
                    <div className="content-item">
                      <span className="data color-orange">
                        {perEval.beforeResult}
                      </span>
                    </div>
                    <div className="content-item">
                      <span className="data">{perEval.afterResult}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* 勤務評価 */}
        <div className="l-board__wrap p-comp__content">
          {comparisonResults &&
            comparisonResults.work_evaluations.map((jobEval, index) => (
              <div className="p-compare__box" key={index}>
                {/* Only display "仕事評価" for the first item */}
                <p className="p-compare__ttl">
                  {index === 0 ? "勤務評価" : ""}
                </p>
                <div className="p-compare__content">
                  <p className="content-headline">{jobEval.name}</p>
                  <div className="content-inner">
                    <div className="content-item">
                      <span className="data color-orange">
                        {jobEval.beforeResult}
                      </span>
                    </div>
                    <div className="content-item">
                      <span className="data">{jobEval.afterResult}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HrCompareEvaluation;
