import React, { useState, useEffect } from 'react';
import { useEducationCategories, useEducationCategoriesWithCurriculum } from "../../../Queries/EducationCategoryQuery";
import { useAssistantList } from "../../../Queries/UserQuery";
import EducationCurriculumFormModal from '../../../Components/Modal/EducationCurriculumFormModal';
import { useAddEducationCurriculum } from '../../../Queries/EducationCurriculumQuery';
import { Link, useNavigate } from 'react-router-dom';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const EducationProgress = (): JSX.Element => {
    const { authority } = useAuthority();
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // 新規登録用のMutation
    const educationCurriculumMutation = useAddEducationCurriculum(handleCloseModal);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: assistants, status: assistantsStatus } = useAssistantList();
    const { data: educationCategories, status: educationCategoriesStatus } = useEducationCategories();
    const [selectedAssistantId, setSelectedAssistantId] = useState(null);

    useEffect(() => {
        if (assistants && assistants.length > 0) {
            setSelectedAssistantId(assistants[0].id);
        } else {
            setSelectedAssistantId(null);
        }
    }, [assistants]);

    // カテゴリー一覧処理
    const [categories, setCategories] = useState<any[]>([]);
    const categoriesQueryResult = useEducationCategoriesWithCurriculum(selectedAssistantId);
    const categoriesData = selectedAssistantId ? categoriesQueryResult.data : null;
    const categoriesDataStatus = selectedAssistantId ? categoriesQueryResult.status : 'idle';

    // hover時にアイコンの画像を変える
    const [hoveredIndex, setHoveredIndex] = useState(null);

    // 達成状況の%の計算式
    const calculateProgressPercentage = (curriculums) => {
        if (!curriculums || curriculums.length === 0) {
            return 0;
        }
        const completedCount = curriculums.filter(curriculum => curriculum.curriculum_progresses && curriculum.curriculum_progresses.length > 0 && curriculum.curriculum_progresses[0].complete_on !== null).length;
        return Math.round((completedCount / curriculums.length) * 100);
    };

    // ユーザー
    const handleAssistantChange = (event) => {
        setSelectedAssistantId(event.target.value);
    }


    useEffect(() => {
        if (categoriesData) {
            setCategories(categoriesData);
        }
    }, [categoriesData]);

    // 選択機能
    const [detailIndex, setDetailIndex] = useState(0);
    const [detailShow, setDetailShow] = useState({});
    const [rotatedIndices, setRotatedIndices] = useState({});

    const handleDetail = (index) => {
        setDetailShow(prevState => {
            const updatedDetailShow = { ...prevState };
            updatedDetailShow[index] = !updatedDetailShow[index];
            return updatedDetailShow;
        });
        setRotatedIndices(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
        setDetailIndex(index);
    };

    const isLoading = educationCategoriesStatus === 'loading' || categoriesDataStatus === 'loading' || assistantsStatus === 'loading';

    if(isLoading) return <LoaderComponent />

    return (
        <>
            <EducationCurriculumFormModal
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                mutationFunction={educationCurriculumMutation}
                educationCategories={educationCategories}
            />
            {!assistants || assistants.length === 0 ? (
                <div className="l-board__wrap p-board__main">
                    <p>該当するアシスタントが存在しません。</p>
                </div>
            ) : (
                <>
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area">
                            <div className="p-board__btn--input">
                                <select
                                    className="c-input c-input__select type-shadow"
                                    onChange={handleAssistantChange}
                                >
                                    {assistants && assistants.map((assistant, index) => (
                                        <option
                                            key={index}
                                            value={assistant.id}
                                        >{assistant.last_name} {assistant.first_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        { (authority === '0' || authority === '1') && (
                            <div className="l-flex p-board__btn--area space-around">
                                <a
                                    className="p-board__btn--item c-btn c-btn__green icon icon-plus pc-only"
                                    onClick={() => setIsModalOpen(true)}
                                >カリキュラム項目を追加</a>
                            </div>
                        )}
                    </div>
                    <div className="l-board__wrap p-board__main">
                        <div className="p-board__main--box">
                            <table className="p-table p-table__more">
                                <thead>
                                    <tr>
                                        <th>カリキュラムカテゴリー</th>
                                        <th>達成状況</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories && categories.map((category, index) => (
                                        <React.Fragment key={category.id}>
                                            <tr
                                                className={`more${index} ${detailShow[index] ? 'is_show' : ''}`}
                                                onClick={() => handleDetail(index)}
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <td>{category.name}</td>
                                                <td>{calculateProgressPercentage(category.education_curriculums)}%</td>
                                                <td></td>
                                                <td className="u-tAlign--right">
                                                    <img
                                                        style={{
                                                            width: '32px',
                                                            transform: rotatedIndices[index] ? 'rotate(90deg)' : 'none',
                                                            transition: 'transform 0.2s ease' // アニメーションを追加
                                                        }}
                                                        src="/image/icon_circle_arrow_right_green.png"
                                                    />
                                                </td>
                                            </tr>

                                            {/* 詳細部分 */}
                                            {/* <tr className={`detail-area detail${index} u-bNone cursor-auto ${detailShow[index] ? 'is_show' : ''}`}> */}
                                            <tr className={`detail-area detail${index} u-bNone cursor-auto ${detailShow[index] ? 'is_show' : ''}`}>
                                                <td colSpan={4}>
                                                    <table className="p-table">
                                                        <thead>
                                                            <tr className="cursor-auto">
                                                                <th>達成状況</th>
                                                                <th>カリキュラム項目</th>
                                                                <th>詳細</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="u-bNone">
                                                            {category.education_curriculums && category.education_curriculums.map(curriculum => (
                                                                <tr
                                                                    key={curriculum.id}
                                                                    onClick={() => navigate(`/hc-user/education/curriculum/result/${selectedAssistantId}/${curriculum.id}`)} // ここでnavigate関数を使用して遷移
                                                                >
                                                                    <td>
                                                                        {curriculum.curriculum_progresses && curriculum.curriculum_progresses.length > 0 && curriculum.curriculum_progresses[0].complete_on !== null ? (
                                                                            <span className="c-tag c-tag__complete">終了</span>
                                                                        ) : (
                                                                            <span className="c-tag c-tag__incomplete">未了</span>
                                                                        )}
                                                                    </td>
                                                                    <td>{curriculum.name}</td>
                                                                    <td>{curriculum.detail}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
export default EducationProgress;
