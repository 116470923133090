// /resources/js/Components/Form/RadioFieldGroup.tsx

const RadioFieldGroup = ({ label, options, name, value, onChange, error }) => {
  return (
    <div className="p-input__area">
      <label className="c-label">{label}</label>
      <div className="u-mt4">
        {options.map((option) => (
          <div className="c-input__checkbox" key={option.value}>
            <input
              id={`${name}_${option.value}`}
              type="radio"
              name={name}
              value={option.value}
              checked={value === option.value} // parseIntを削除
              onChange={(e) => onChange(e.target.value)} // parseIntを削除
            />
            <label htmlFor={`${name}_${option.value}`}>{option.label}</label>
          </div>
        ))}
      </div>
      {error && <p className="p-input__error">{error}</p>}
    </div>
  );
};

export default RadioFieldGroup;
