import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
/* Query */
import { useAssistantList, useUsersForSelectQuery } from "../../../Queries/UserQuery"
import { useYearMonthSinceCreation } from '../../../Queries/CompanyQuery';
import { useAddCurriculumResult } from '../../../Queries/CurriculumResultQuery';
import { useFetchUserCurriculums } from '../../../Queries/EducationCurriculumQuery';
import { useFetchResultsByUserAndDate } from '../../../Queries/CurriculumResultQuery';
/* component */
import LoaderComponent from '../../../Components/Layout/Loader';
import CurriculumResultAddModal from '../../../Components/Modal/CurriculumResultAddModal';
import { useAuthority } from '../../../Contexts/AuthorityContext';
import { formatDate } from '../../../Utils/formatUtils';


const EducationResult = (): JSX.Element => {
    const { authority } = useAuthority();
    const navigate = useNavigate();
    const queryParams = queryString.parse(location.search);

    /* 追加モーダル */
    const [isAddModal, setIsAddModal] = useState(false);

    /* ページ内のデータ */
    const [selectedUserId, setSelectedUserId] = useState('')
    const [selectedYearMonth, setSelectedYearMonth] = useState('')

    /* API */
    const { data: users, status: usersStatus } = useUsersForSelectQuery();
    const { data: assistants, status: assistantsStatus } = useAssistantList();
    const { data: yearMonthList, status: yearMonthListStatus } = useYearMonthSinceCreation()
    const { data: educationCurriculumList, status: educationCurriculumListStatus } = useFetchUserCurriculums();

    const handleCloseModal = () => {
        setIsAddModal(false);
    };
    /* モーダルの登録処理 */
    const curriculumResultMutation = useAddCurriculumResult(handleCloseModal);


    // selectタブの初期値
    useEffect(() => {
        if (assistants && assistants.length > 0) {
            setSelectedUserId(assistants[0].id);
        }
        if (yearMonthList && yearMonthList.length > 0) {
            setSelectedYearMonth(yearMonthList[0]);
        }
    }, [assistants, yearMonthList]);

    /* 教育実績データ */
    // const { data: results, status: resultsStatus } = useFetchResultsByUserAndDate(selectedUserId, selectedYearMonth);
    const { data: results, status: resultsStatus } = useFetchResultsByUserAndDate(Number(selectedUserId), selectedYearMonth);

    /* 選択系 */
    // 年月
    const handleYearMonthChange = (event) => {
        setSelectedYearMonth(event.target.value);
    }

    // ユーザー
    const handleUserChange = (event) => {
        setSelectedUserId(event.target.value);
    }

    /* 追加モーダル */
    const handleAddModalOpen = () => {
        setIsAddModal(true);
    };

    const isLoading =
        usersStatus === 'loading' ||
        resultsStatus === 'loading' ||
        assistantsStatus === 'loading' ||
        yearMonthListStatus === 'loading' ||
        educationCurriculumListStatus === 'loading';

    const formatTime = (time: string): string => {
        const [hour, minute] = time.split(':');
        return `${parseInt(hour, 10)}:${minute}`;
    };


    useEffect(() => {
        if (queryParams.yearMonth) {
            setSelectedYearMonth(queryParams.yearMonth as string);
        }

        if (queryParams.userId) {
            setSelectedUserId(queryParams.userId as string);
        }
    }, [queryParams.yearMonth, queryParams.userId]);

    if(isLoading) return <LoaderComponent />;

    return (
        <>
            <CurriculumResultAddModal
                isOpen={isAddModal}
                isClose={handleCloseModal}
                onAction={curriculumResultMutation}
                userList={users}
                assistantList={assistants}
                educationCurriculumList={educationCurriculumList}
            />

            {assistants && assistants.length > 0 ? (
                <>
                    <div className="l-board__wrap p-board__btn no-border">
                        <div className="l-flex p-board__btn--area space-around">
                            <div className="p-board__btn--input">
                                <select
                                    className="c-input c-input__select type-shadow"
                                    onChange={handleYearMonthChange}
                                    value={selectedYearMonth}
                                >
                                    {yearMonthList && yearMonthList.map((yearMonth, index) => {
                                        // "-" で分割して、年と月を取得
                                        const [year, month] = yearMonth.split('-');
                                        return (
                                            <option
                                                key={index}
                                                value={yearMonth}
                                            >{`${year}年${month}月`}</option>
                                        );
                                    })}

                                </select>
                            </div>
                            <div className="p-board__btn--input">
                                <select
                                    className="c-input c-input__select type-shadow"
                                    onChange={handleUserChange}
                                    value={selectedUserId}
                                >
                                    {assistants && assistants.map((user, index) => (
                                        <option
                                            key={index}
                                            value={user.id}
                                        >{user.last_name} {user.first_name}</option>
                                    ))}
                                </select>
                            </div>
                            { (authority === '0' || authority === '1') && (
                                <a
                                    className="p-board__btn--item c-btn c-btn__green icon icon-plus pc-only"
                                    onClick={handleAddModalOpen}
                                >教育実績を追加</a>
                            )}
                        </div>
                    </div>
                    <div className="l-board__wrap p-board__main">
                        <div className="p-board__main--box">
                            <table className="p-table">
                                <thead>
                                    <tr>
                                        <th>日付</th>
                                        <th>時間</th>
                                        <th>指導スタッフ</th>
                                        <th>教育内容</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results?.data?.map(result => (
                                        <tr
                                            key={result.id}
                                            onClick={() => navigate(`/hc-user/education/result/detail/${result.id}`)}
                                        >
                                            <td>{result.done_on}</td>
                                            <td>{formatTime(result.start_time)}-{formatTime(result.finish_time)}</td>
                                            <td>{result.educator_name}</td>
                                            <td>{result.curriculum_detail}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            ) : (
                <div className="l-board__wrap p-board__main">該当するアシスタントが存在しません。</div>
            )}
        </>
    );
};
export default EducationResult;
