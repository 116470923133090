import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useUsersForSelectQuery } from "../../../Queries/UserQuery";
import { useEvaluationPeriods } from '../../../Queries/EvaluationRuleQuery';
import { fetchUserTotalInEvaluationPeriod } from '../../../Queries/PerformanceResultQuery';

const HrPerformance = (): JSX.Element => {

    /* ページ内データ */
    const [selectedUserId, setSelectedUserId] = useState<number | string>('');
    const [selectedPeriodStart, setSelectedPeriodStart] = useState<string>('');
    const [selectedPeriodEnd, setSelectedPeriodEnd] = useState<string>('');

    /* 取得API */
    const { data: users, status: usersStatus } = useUsersForSelectQuery();
    const { data: periods, status: periodsStatus } = useEvaluationPeriods();

    const [performanceData, setPerformanceData] = useState(null);

    /* ローディング */
    const [isLoading, setIsLoading] = useState(true);

    /* format群 */
    // 年月
    const formatPeriod = (period) => {
        let startYear = period.start.slice(0, 4).slice(-2); // Extract last two digits of year
        let startMonth = parseInt(period.start.slice(4, 6), 10); // Extract month part and convert string to integer
        let endYear = period.end.slice(0, 4).slice(-2);
        let endMonth = parseInt(period.end.slice(4, 6), 10);
        return `${startYear}年${startMonth}月-${endYear}年${endMonth}月`;
    }
    // 数字に対して3桁ごとにカンマを打つプログラム
    const formatNumber = (number) => {
        return new Intl.NumberFormat('ja-JP').format(number);
    };

    useEffect(() => {
        if (users && users.length > 0) {
            setSelectedUserId(users[0].id);
        }
        if (periods && periods.length > 0) {
            setSelectedPeriodStart(periods[0].start);
            setSelectedPeriodEnd(periods[0].end);
        }
    }, [users, periods]);

    /* ローディング初期時 */
    useEffect(() => {
        setIsLoading(usersStatus === 'loading' || periodsStatus === 'loading');
    }, [usersStatus, periodsStatus]);

    /* 選択系 */
    const handleUserChange = (event) => {
        setSelectedUserId(event.target.value);
        setIsLoading(true);  // 1. ローディング状態をtrueに設定
    }
    const handlePeriodChange = (event) => {
        const [start, end] = event.target.value.split('-');
        setSelectedPeriodStart(start);
        setSelectedPeriodEnd(end);
        setIsLoading(true);  // 1. ローディング状態をtrueに設定
    };

    useEffect(() => {
        if (selectedUserId && selectedPeriodStart) {
            const fetchData = async () => {
                try {
                    const year = selectedPeriodStart.slice(0, 4);
                    const month = selectedPeriodStart.slice(4, 6);
                    const data = await fetchUserTotalInEvaluationPeriod(selectedUserId, year, month);
                    if (data) {
                        setPerformanceData(data);
                    } else {
                        console.error("No data received from fetchUserTotalInEvaluationPeriod");
                    }
                } catch (error) {
                    console.error("Failed to fetch performance data:", error.message);
                } finally {
                    setIsLoading(false);  // 2. データ取得が完了したらローディング状態をfalseに設定
                }
            };
            fetchData();
        }
    }, [selectedUserId, selectedPeriodStart]);

    if(isLoading) return <LoaderComponent />;

    return (
        <>
            <div className="l-board__wrap p-board__btn">
                <div className="l-flex p-board__btn--area">
                    <div className="p-board__btn--input">
                        <select
                            className="c-input c-input__select type-shadow"
                            onChange={handlePeriodChange}
                            value={`${selectedPeriodStart}-${selectedPeriodEnd}`}
                        >
                            {periods && periods.map((period, index) => ( // 3. Map through the periods
                                <option key={index} value={`${period.start}-${period.end}`}>
                                    {formatPeriod(period)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="p-board__btn--input">
                        <select
                            className="c-input c-input__select type-shadow"
                            onChange={handleUserChange}
                            value={selectedUserId}
                        >
                            {users && users.map((user, index) => (
                                <option
                                    key={index}
                                    value={user.id}
                                >{user.last_name} {user.first_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="l-board__wrap p-board__main">
                <div className="p-board__main--box">
                    <ul className="p-list__master">
                        {performanceData && performanceData.map((item, index) => (
                            <li key={index} className="p-list__master--item">
                                <Link to={`/hc-user/hr/performance/detail/${selectedUserId}/${item.id}/${selectedPeriodStart.slice(0, 4)}/${selectedPeriodStart.slice(4, 6)}`}>
                                    <div className="c-performance__box">
                                        <p className="c-performance__headline">{item.name}</p>
                                        <p className="c-performance__data">{formatNumber(item.total)}円</p>
                                        <p className="c-performance__sub">(目標：{formatNumber(item.goal)}円)</p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default HrPerformance;
