import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { useUserProfile } from "../../Queries/UserQuery";
import urlPatterns from "../../urlPatterns";
import { toast } from "react-toastify";

function UserLayout() {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [csrfToken, setCsrfToken] = useState("");
  const { data: profile } = useUserProfile();

  /* 戻る機能 */
  const [title, setTitle] = useState("見出し");
  const [backUrl, setBackUrl] = useState("");
  const [activeStates, setActiveStates] = useState({
    isAttActive: false, // 勤怠
    isEduActive: false, // 教育・研修
    isPerActive: false, // 業績
    isHrActive: false, // 人事考課
    isStaActive: false, // スタッフ
    isShoActive: false, // 店舗
    isComActive: false, // 会社
  });

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const { data } = await axios.get("/csrf-token");
      setCsrfToken(data.csrfToken);
    };
    fetchCsrfToken();
  }, []);

  /* 各ページのコンポーネント */
  useEffect(() => {
    for (const patternObj of urlPatterns) {
      if (pathname.match(patternObj.pattern)) {
        const result = patternObj.action();
        if (result) {
          // 追加: resultが存在するか確認する
          setTitle(result.title || title);
          setBackUrl(result.url || "");
          setActiveStates((prevState) => ({
            isAttActive: false,
            isEduActive: false,
            isPerActive: false,
            isHrActive: false,
            isStaActive: false,
            isShoActive: false,
            isComActive: false,
            ...result,
          }));
        }
        break;
      }
    }
  }, [pathname]);

  // ログアウト機能
  const handleLogout = async () => {
    try {
      const response = await axios.post(
        "/logout",
        {},
        {
          headers: {
            "X-CSRF-TOKEN": csrfToken,
          },
        }
      );
      if (response.status === 204 || response.status === 200) {
        window.location.href = "/login";
      } else {
        toast.error("ログアウトに失敗しました。");
      }
    } catch (error) {}
  };

  return (
    <>
      {/* ヘッダー */}
      <header className="l-header">
        <div className="l-header__wrap">
          <div className="l-header__profile">
            <Link className="profile-button" to="/hc-user/mypage/">
              <img src={"/image/icon_profile.png"} />
              <p>
                {profile?.lastName} {profile?.firstName}
              </p>
            </Link>
          </div>
          <div className="l-header__headline">
            {backUrl && (
              <Link to={backUrl} className="c-btn c-btn__back">
                <img src={"/image/icon_arrow_left_green.png"} />
              </Link>
            )}
            <h1 className="ttl">{title}</h1>
          </div>
          <div className="l-header__menu">
            <input id="drawer-checkbox" type="checkbox" />
            <label id="drawer-icon" htmlFor="drawer-checkbox">
              <span></span>
              <span></span>
              <span></span>
            </label>
            <label id="drawer-close" htmlFor="drawer-checkbox"></label>
            <nav id="drawer-content">
              <ul className="p-header">
                <li className="p-header__item sp-only">
                  <Link
                    to="/hc-user/attendance/detail/"
                    className="p-header__link"
                  >
                    勤怠
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link
                    to="/hc-user/education/progress/"
                    className="p-header__link"
                  >
                    教育・研修
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link
                    to="/hc-user/performance/shop/"
                    className="p-header__link"
                  >
                    業績
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/hr/evaluation" className="p-header__link">
                    人事考課
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/staff/" className="p-header__link">
                    スタッフ
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/shop/" className="p-header__link">
                    店舗
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link
                    to="/hc-user/company/detail/"
                    className="p-header__link"
                  >
                    会社
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/mypage/" className="p-header__link">
                    マイページ
                  </Link>
                </li>
                <li className="p-header__item">
                  <a onClick={handleLogout} className="p-header__link">
                    ログアウト
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      {/* ナビ */}
      <nav className="l-nav">
        <div className="p-nav">
          <div className="p-nav__logo">
            <img src={"/image/logo_HAIRCULUM.png"} width="118" height="24" />
          </div>
          <ul className="p-nav__link">
            {/* 勤怠 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isAttActive ? "active" : ""
                }`}
                to="/hc-user/attendance/detail/"
              >
                勤怠
                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.548 13.098">
                                    <path
                                        id="iconmonstr-user-22"
                                        d="M8.157,13.725c1.849-.427,3.569-.8,2.736-2.375C8.357,6.561,10.22,4,12.9,4c2.731,0,4.549,2.659,2.006,7.351-.859,1.583.926,1.957,2.735,2.375,1.6.369,1.707,1.151,1.707,2.5l0,.667H18.274c0-1.684.067-1.908-.988-2.152-1.532-.353-2.979-.687-3.508-1.857a2.225,2.225,0,0,1,.182-2.05c1.106-2.041,1.386-3.81.765-4.851a2.275,2.275,0,0,0-3.637-.018c-.619,1.026-.343,2.8.755,4.877a2.24,2.24,0,0,1,.166,2.05c-.532,1.165-1.993,1.5-3.539,1.858-1.009.234-.945.474-.945,2.142H6.452c0-1.77-.142-2.746,1.706-3.173ZM0,16.9H1.073c0-1.546-.15-1.117,1.47-1.591a2.685,2.685,0,0,0,1.867-1.3,1.934,1.934,0,0,0-.131-1.771c-.767-1.449-.983-2.72-.574-3.4a1.443,1.443,0,0,1,2.273.012c.754,1.263-.639,3.21-.822,4.016H6.282a7.2,7.2,0,0,0,.972-3.144,2.307,2.307,0,0,0-2.417-2.5c-2.01,0-3.408,1.921-1.5,5.513.624,1.18-.666,1.461-2.052,1.781C.08,14.8,0,15.383,0,16.4l0,.5Z"
                                        transform="translate(0.1 -3.9)"
                                        stroke="var(--color-green)"
                                        strokeWidth="0.3"/>
                                </svg>勤怠 */}
              </NavLink>
            </li>
            {/* 教育・研修 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isEduActive ? "active" : ""
                }`}
                to="/hc-user/education/progress/"
              >
                教育・研修
                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.548 13.098">
                                    <path
                                        id="iconmonstr-user-22"
                                        d="M8.157,13.725c1.849-.427,3.569-.8,2.736-2.375C8.357,6.561,10.22,4,12.9,4c2.731,0,4.549,2.659,2.006,7.351-.859,1.583.926,1.957,2.735,2.375,1.6.369,1.707,1.151,1.707,2.5l0,.667H18.274c0-1.684.067-1.908-.988-2.152-1.532-.353-2.979-.687-3.508-1.857a2.225,2.225,0,0,1,.182-2.05c1.106-2.041,1.386-3.81.765-4.851a2.275,2.275,0,0,0-3.637-.018c-.619,1.026-.343,2.8.755,4.877a2.24,2.24,0,0,1,.166,2.05c-.532,1.165-1.993,1.5-3.539,1.858-1.009.234-.945.474-.945,2.142H6.452c0-1.77-.142-2.746,1.706-3.173ZM0,16.9H1.073c0-1.546-.15-1.117,1.47-1.591a2.685,2.685,0,0,0,1.867-1.3,1.934,1.934,0,0,0-.131-1.771c-.767-1.449-.983-2.72-.574-3.4a1.443,1.443,0,0,1,2.273.012c.754,1.263-.639,3.21-.822,4.016H6.282a7.2,7.2,0,0,0,.972-3.144,2.307,2.307,0,0,0-2.417-2.5c-2.01,0-3.408,1.921-1.5,5.513.624,1.18-.666,1.461-2.052,1.781C.08,14.8,0,15.383,0,16.4l0,.5Z"
                                        transform="translate(0.1 -3.9)"
                                        strokeWidth="0.3"/>
                                </svg>教育・研修 */}
              </NavLink>
            </li>
            {/* 業績 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isPerActive ? "active" : ""
                }`}
                to="/hc-user/performance/shop/"
              >
                業績
                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.548 13.098">
                                    <path
                                        id="iconmonstr-user-22"
                                        d="M8.157,13.725c1.849-.427,3.569-.8,2.736-2.375C8.357,6.561,10.22,4,12.9,4c2.731,0,4.549,2.659,2.006,7.351-.859,1.583.926,1.957,2.735,2.375,1.6.369,1.707,1.151,1.707,2.5l0,.667H18.274c0-1.684.067-1.908-.988-2.152-1.532-.353-2.979-.687-3.508-1.857a2.225,2.225,0,0,1,.182-2.05c1.106-2.041,1.386-3.81.765-4.851a2.275,2.275,0,0,0-3.637-.018c-.619,1.026-.343,2.8.755,4.877a2.24,2.24,0,0,1,.166,2.05c-.532,1.165-1.993,1.5-3.539,1.858-1.009.234-.945.474-.945,2.142H6.452c0-1.77-.142-2.746,1.706-3.173ZM0,16.9H1.073c0-1.546-.15-1.117,1.47-1.591a2.685,2.685,0,0,0,1.867-1.3,1.934,1.934,0,0,0-.131-1.771c-.767-1.449-.983-2.72-.574-3.4a1.443,1.443,0,0,1,2.273.012c.754,1.263-.639,3.21-.822,4.016H6.282a7.2,7.2,0,0,0,.972-3.144,2.307,2.307,0,0,0-2.417-2.5c-2.01,0-3.408,1.921-1.5,5.513.624,1.18-.666,1.461-2.052,1.781C.08,14.8,0,15.383,0,16.4l0,.5Z"
                                        transform="translate(0.1 -3.9)"
                                        strokeWidth="0.3"/>
                                </svg>業績 */}
              </NavLink>
            </li>
            {/* 人事考課 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isHrActive ? "active" : ""
                }`}
                to="/hc-user/hr/evaluation"
              >
                人事考課
                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.548 13.098">
                                    <path
                                        id="iconmonstr-user-22"
                                        d="M8.157,13.725c1.849-.427,3.569-.8,2.736-2.375C8.357,6.561,10.22,4,12.9,4c2.731,0,4.549,2.659,2.006,7.351-.859,1.583.926,1.957,2.735,2.375,1.6.369,1.707,1.151,1.707,2.5l0,.667H18.274c0-1.684.067-1.908-.988-2.152-1.532-.353-2.979-.687-3.508-1.857a2.225,2.225,0,0,1,.182-2.05c1.106-2.041,1.386-3.81.765-4.851a2.275,2.275,0,0,0-3.637-.018c-.619,1.026-.343,2.8.755,4.877a2.24,2.24,0,0,1,.166,2.05c-.532,1.165-1.993,1.5-3.539,1.858-1.009.234-.945.474-.945,2.142H6.452c0-1.77-.142-2.746,1.706-3.173ZM0,16.9H1.073c0-1.546-.15-1.117,1.47-1.591a2.685,2.685,0,0,0,1.867-1.3,1.934,1.934,0,0,0-.131-1.771c-.767-1.449-.983-2.72-.574-3.4a1.443,1.443,0,0,1,2.273.012c.754,1.263-.639,3.21-.822,4.016H6.282a7.2,7.2,0,0,0,.972-3.144,2.307,2.307,0,0,0-2.417-2.5c-2.01,0-3.408,1.921-1.5,5.513.624,1.18-.666,1.461-2.052,1.781C.08,14.8,0,15.383,0,16.4l0,.5Z"
                                        transform="translate(0.1 -3.9)"
                                        strokeWidth="0.3"/>
                                </svg>人事考課 */}
              </NavLink>
            </li>
            {/* スタッフ */}
            <li className="p-nav__link--item pc-only">
              <NavLink
                className={`icon-people ${
                  activeStates.isStaActive ? "active" : ""
                }`}
                to="/hc-user/staff/"
              >
                スタッフ
                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.548 13.098">
                                    <path
                                        id="iconmonstr-user-22"
                                        d="M8.157,13.725c1.849-.427,3.569-.8,2.736-2.375C8.357,6.561,10.22,4,12.9,4c2.731,0,4.549,2.659,2.006,7.351-.859,1.583.926,1.957,2.735,2.375,1.6.369,1.707,1.151,1.707,2.5l0,.667H18.274c0-1.684.067-1.908-.988-2.152-1.532-.353-2.979-.687-3.508-1.857a2.225,2.225,0,0,1,.182-2.05c1.106-2.041,1.386-3.81.765-4.851a2.275,2.275,0,0,0-3.637-.018c-.619,1.026-.343,2.8.755,4.877a2.24,2.24,0,0,1,.166,2.05c-.532,1.165-1.993,1.5-3.539,1.858-1.009.234-.945.474-.945,2.142H6.452c0-1.77-.142-2.746,1.706-3.173ZM0,16.9H1.073c0-1.546-.15-1.117,1.47-1.591a2.685,2.685,0,0,0,1.867-1.3,1.934,1.934,0,0,0-.131-1.771c-.767-1.449-.983-2.72-.574-3.4a1.443,1.443,0,0,1,2.273.012c.754,1.263-.639,3.21-.822,4.016H6.282a7.2,7.2,0,0,0,.972-3.144,2.307,2.307,0,0,0-2.417-2.5c-2.01,0-3.408,1.921-1.5,5.513.624,1.18-.666,1.461-2.052,1.781C.08,14.8,0,15.383,0,16.4l0,.5Z"
                                        transform="translate(0.1 -3.9)"
                                        strokeWidth="0.3"/>
                                </svg>スタッフ */}
              </NavLink>
            </li>
            {/* 店舗 */}
            <li className="p-nav__link--item pc-only">
              <NavLink
                className={`icon-people ${
                  activeStates.isShoActive ? "active" : ""
                }`}
                to="/hc-user/shop/"
              >
                店舗
                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.548 13.098">
                                    <path
                                        id="iconmonstr-user-22"
                                        d="M8.157,13.725c1.849-.427,3.569-.8,2.736-2.375C8.357,6.561,10.22,4,12.9,4c2.731,0,4.549,2.659,2.006,7.351-.859,1.583.926,1.957,2.735,2.375,1.6.369,1.707,1.151,1.707,2.5l0,.667H18.274c0-1.684.067-1.908-.988-2.152-1.532-.353-2.979-.687-3.508-1.857a2.225,2.225,0,0,1,.182-2.05c1.106-2.041,1.386-3.81.765-4.851a2.275,2.275,0,0,0-3.637-.018c-.619,1.026-.343,2.8.755,4.877a2.24,2.24,0,0,1,.166,2.05c-.532,1.165-1.993,1.5-3.539,1.858-1.009.234-.945.474-.945,2.142H6.452c0-1.77-.142-2.746,1.706-3.173ZM0,16.9H1.073c0-1.546-.15-1.117,1.47-1.591a2.685,2.685,0,0,0,1.867-1.3,1.934,1.934,0,0,0-.131-1.771c-.767-1.449-.983-2.72-.574-3.4a1.443,1.443,0,0,1,2.273.012c.754,1.263-.639,3.21-.822,4.016H6.282a7.2,7.2,0,0,0,.972-3.144,2.307,2.307,0,0,0-2.417-2.5c-2.01,0-3.408,1.921-1.5,5.513.624,1.18-.666,1.461-2.052,1.781C.08,14.8,0,15.383,0,16.4l0,.5Z"
                                        transform="translate(0.1 -3.9)"
                                        strokeWidth="0.3"/>
                                </svg>店舗 */}
              </NavLink>
            </li>
            {/* 会社 */}
            <li className="p-nav__link--item pc-only">
              <NavLink
                className={`icon-people ${
                  activeStates.isComActive ? "active" : ""
                }`}
                to="/hc-user/company/detail/"
              >
                会社
                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.548 13.098">
                                    <path
                                        id="iconmonstr-user-22"
                                        d="M8.157,13.725c1.849-.427,3.569-.8,2.736-2.375C8.357,6.561,10.22,4,12.9,4c2.731,0,4.549,2.659,2.006,7.351-.859,1.583.926,1.957,2.735,2.375,1.6.369,1.707,1.151,1.707,2.5l0,.667H18.274c0-1.684.067-1.908-.988-2.152-1.532-.353-2.979-.687-3.508-1.857a2.225,2.225,0,0,1,.182-2.05c1.106-2.041,1.386-3.81.765-4.851a2.275,2.275,0,0,0-3.637-.018c-.619,1.026-.343,2.8.755,4.877a2.24,2.24,0,0,1,.166,2.05c-.532,1.165-1.993,1.5-3.539,1.858-1.009.234-.945.474-.945,2.142H6.452c0-1.77-.142-2.746,1.706-3.173ZM0,16.9H1.073c0-1.546-.15-1.117,1.47-1.591a2.685,2.685,0,0,0,1.867-1.3,1.934,1.934,0,0,0-.131-1.771c-.767-1.449-.983-2.72-.574-3.4a1.443,1.443,0,0,1,2.273.012c.754,1.263-.639,3.21-.822,4.016H6.282a7.2,7.2,0,0,0,.972-3.144,2.307,2.307,0,0,0-2.417-2.5c-2.01,0-3.408,1.921-1.5,5.513.624,1.18-.666,1.461-2.052,1.781C.08,14.8,0,15.383,0,16.4l0,.5Z"
                                        transform="translate(0.1 -3.9)"
                                        strokeWidth="0.3"/>
                                </svg>会社 */}
              </NavLink>
            </li>
          </ul>
          <a className="p-nav__logout" onClick={handleLogout}>
            ログアウト
          </a>
        </div>
      </nav>
    </>
  );
}
export default UserLayout;
