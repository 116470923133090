import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { useUserAuthorityQuery } from "../Queries/UserQuery";

type AuthorityType = 0 | 1 | 2 | 9;

interface AuthorityContextProps {
  authority: AuthorityType | null;
  userShopId: number | null;
  userId: number | null;
  isLoading: boolean;
}

const AuthorityContext = createContext<AuthorityContextProps>({
  authority: null,
  userShopId: null,
  userId: null,
  isLoading: true,
});

interface AuthorityProviderProps {
  children: ReactNode;
}

export const AuthorityProvider: React.FC<AuthorityProviderProps> = ({ children }) => {
  const [authority, setAuthority] = useState<AuthorityType | null>(null);
  const [userShopId, setUserShopId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const {
    data: userAuthorityResponse,
    isLoading,
    error,
  } = useUserAuthorityQuery();

  useEffect(() => {
    if (userAuthorityResponse) {
      console.log("User authority data:", userAuthorityResponse);
      setAuthority(Number(userAuthorityResponse.authority) as AuthorityType);
      setUserShopId(Number(userAuthorityResponse.shopId));
      setUserId(Number(userAuthorityResponse.id));
    }
    if (error) {
      console.error("Error fetching user authority:", error);
    }
  }, [userAuthorityResponse, error]);

  return (
    <AuthorityContext.Provider value={{ authority, userShopId, userId, isLoading }}>
      {children}
    </AuthorityContext.Provider>
  );
};

export const useAuthority = () => useContext(AuthorityContext);
